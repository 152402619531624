import React, { useState, useRef, DragEvent, ChangeEvent } from 'react';
// import { CgSpinner } from "react-icons/cg";

interface CustomFileInputProps {
    onChange: (file: File | null) => void;
    placeholder?: string;
    accept?: string;
    maxSizeMB?: number;
    disabled?: boolean;
}

const CustomFileInput: React.FC<CustomFileInputProps> = ({
    onChange,
    placeholder = 'Click or drag and drop an image here',
    accept = 'image/*',
    maxSizeMB = 5,
    disabled = false
}) => {
    const [dragging, setDragging] = useState(false);
    const [preview, setPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const isValidFileType = (file: File): boolean => {
        const acceptedTypes = accept.split(',').map(type => type.trim());
        return acceptedTypes.some(type => {
            if (type === 'image/*') return file.type.startsWith('image/');
            return file.type === type;
        });
    };

    const handleFile = (file: File | null) => {
        if (disabled) return;
        if (file) {
            if (!isValidFileType(file)) {
                alert('Unsupported file type. Please upload an image file.');
                return;
            }
            if (file.size > maxSizeMB * 1024 * 1024) {
                alert(`File size should not exceed ${maxSizeMB}MB`);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target?.result as string);
            reader.readAsDataURL(file);
            onChange(file);
        } else {
            setPreview(null);
            onChange(null);
        }
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleFile(files[0]);
        }
    };

    const handleClick = () => {
        if (disabled) return;
        fileInputRef.current?.click();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        const files = e.target.files;
        if (files && files.length > 0) {
            handleFile(files[0]);
        }
    };

    return (
        <div
            className={`relative w-[200px] h-[200px] border-2 border-dashed rounded-lg overflow-hidden
            ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
            ${dragging ? 'border-purple-500 bg-blue-50 dark:bg-blue-500' : 'border-slate-500 dark:border-neutral-400 bg-neutral-50 dark:bg-neutral-800 '}
            ${!disabled && 'hover:bg-gray-100'}
            transition-colors duration-300 ease-in-out`}
            onClick={handleClick}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleChange}
                accept={accept}
                className="hidden"
                disabled={disabled}
            />
            {preview ? (
                <img src={preview} alt="Preview" className="w-full h-full object-cover" />
            ) : (
                <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
                    <svg className="w-10 h-10 text-slate-500 dark:text-neutral-300 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p className="text-sm text-slate-500 dark:text-neutral-300">{placeholder}</p>
                </div>
            )}

            {/* {disabled && <div className='absolute w-[inherit] h-[inherit] bg-black bg-opacity-70 z-10 top-0 left-0 text-neutral-200 flex justify-center items-center'>
                <CgSpinner className='text-[30px] animate-spin' />
            </div>} */}
        </div>
    );
};

export default CustomFileInput;