import { useApp } from '@/contexts/appContext';
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom'; import {
    ListItem,
    UnorderedList,
} from '@chakra-ui/react'

function KYC() {

    const { user } = useApp();
    const [searchParams, setSearchParams] = useSearchParams();
    const { setShowOnboard } = useApp();
    const navigate = useNavigate();

    const urlWallet = useMemo(() => {
        const t = searchParams.get("tier");
        if (!t) return "1";
        if (["1", "2", "3"].includes(t)) {
            return t;
        } else {
            return "1"
        }
    }, [searchParams])

    const [kycTab, setKycTab] = useState(urlWallet);

    function verifyTier1(step = "2") {
        setShowOnboard("bvn");
        navigate(`/onboarding/verify-bvn?tier=${step}`);
    }

    function isVerified(type: string) {
        const getInfo = user!.kyc.details.find((e) => e.parameter === type);
        if (!getInfo) return false;
        return getInfo.status === "Successful" ? true : false;
    }

    function setTab(tab: string) {
        setKycTab(tab);
        setSearchParams({ tier: tab })
    }

    return (
        <>
            <div className='pt-8 md:pt-7'>
                <div className='mb-10 px-6'>
                    <div className='font-extrabold text-xl'>KYC</div>
                </div>

                {/* <div className='px-6 mb-5'>Current KYC level</div> */}

                <div className='mb-8 px-6'>
                    <div className='flex justify-between'>
                        <div className='flex items-center gap-2'>
                            <button onClick={() => setTab("1")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                'outline-none outline-3 outline-purple-700': kycTab === "1",
                                '': kycTab !== "1"
                            })}>
                                {/* <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" />  */}
                                <div className='md:pr-0'>Tier 1</div>
                            </button>
                            <button onClick={() => setTab("2")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                'outline-none outline-3 outline-purple-700': kycTab === "2",
                                '': kycTab !== "2"
                            })}>
                                {/* <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> */}
                                <div className='md:pr-0'>Tier 2</div>
                            </button>
                            <button onClick={() => setTab("3")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                'outline-none outline-3 outline-purple-700': kycTab === "3",
                                '': kycTab !== "3"
                            })}>
                                {/* <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> */}
                                <div className='md:pr-0'>Tier 3</div>
                            </button>

                        </div>
                    </div>
                </div>

                <div className='px-6'>
                    <div className='grid grid-cols-1 md:grid-cols-2'>
                        {
                            // BVN tab
                            kycTab === "1" ?
                                <div className='mb-5  pt-2'>

                                    <div>
                                        <div className='mb-10'>
                                            <div className='text-md font-bold'>Requirements</div>
                                            <div className='text-sm'>Below are the requirements to complete Tier 1 KYC</div>
                                        </div>

                                        <div className='mb-6'>
                                            <div className='flex items-center justify-between gap-3 mb-6'>
                                                <div className=''>
                                                    <div className='flex mb-0'>
                                                        <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-600 dark:text-neutral-300'>BVN</span></div>
                                                    </div>
                                                    <div className='flex mb-2 pl-2'>
                                                        <div className='text-[13px]'>
                                                            <span className='text-neutral-600 dark:text-neutral-300'>
                                                                Provide your 11 digit Bank verification number
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        isVerified("bvn") ?
                                                            <button className='text-xs text-green-600 dark:text-green-500 flex items-center gap-1'>
                                                                <BsCheckCircle />
                                                                <span>Completed</span>
                                                            </button> :
                                                            <button onClick={() => verifyTier1()} className='text-sm text-yellow-600 dark:text-yellow-500'>update</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mb-10'>
                                            <div className='flex items-center justify-between gap-3 mb-6'>
                                                <div className=''>
                                                    <div className='flex mb-0'>
                                                        <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-700 dark:text-neutral-300'>BVN Phone number</span></div>
                                                    </div>
                                                    <div className='flex mb-2 pl-2'>
                                                        <div className='text-[13px]'>
                                                            <span className='text-neutral-700 dark:text-neutral-300'>
                                                                Verify the phone number attached to your BVN
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        isVerified("bvn_phone_number") ?
                                                            <button className='text-xs text-green-600 dark:text-green-500 flex items-center gap-1'>
                                                                <BsCheckCircle />
                                                                <span>Completed</span>
                                                            </button> :
                                                            <button onClick={() => verifyTier1()} className='text-sm text-yellow-600 dark:text-yellow-500'>update</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> :

                                // NIN tab
                                kycTab === "2" ?
                                    <div className='mb-5 pt-2'>

                                        <div>
                                            <div className='mb-10'>
                                                <div className='text-md font-bold'>Requirements</div>
                                                <div className='text-sm'>Below are the requirements to complete Tier 2 KYC</div>
                                            </div>

                                            <div className='mb-6'>
                                                <div className='flex items-center justify-between gap-3 mb-6'>
                                                    <div className=''>
                                                        <div className='flex mb-0'>
                                                            <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-600 dark:text-neutral-300'>NIN</span></div>
                                                        </div>
                                                        <div className='flex mb-2 pl-2'>
                                                            <div className='text-[13px]'>
                                                                <span className='text-neutral-600 dark:text-neutral-300'>
                                                                    Provide your 11 digit National Identity Number
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            isVerified("nin") ?
                                                                <button className='text-xs text-green-600 dark:text-green-500 flex items-center gap-1'>
                                                                    <BsCheckCircle />
                                                                    <span>Completed</span>
                                                                </button> :
                                                                <button onClick={() => navigate("/onboarding/verify-nin")} className='text-sm text-yellow-600 dark:text-yellow-500'>update</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> :

                                    // Residential address tab
                                    <div className='mb-5 pt-2'>
                                        <div>
                                            <div className='mb-10'>
                                                <div className='text-md font-bold'>Requirements</div>
                                                <div className='text-sm'>Below are the requirements to complete Tier 3 KYC</div>
                                            </div>

                                            <div className='mb-6'>
                                                <div className='flex items-center justify-between gap-3 mb-6'>
                                                    <div className=''>
                                                        <div className='flex mb-2'>
                                                            <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-600 dark:text-neutral-300'>Residential address</span></div>
                                                        </div>
                                                        <div className='flex mb-2 pl-2'>
                                                            <div className='text-[13px] pl-1'>
                                                                <div className='text-neutral-600 dark:text-neutral-300 mb-1'>
                                                                    Provide proof of residence. This includes
                                                                </div>
                                                                <div className='text-neutral-600 dark:text-neutral-300'>
                                                                    <UnorderedList>
                                                                        <ListItem>Utilty bill or statement of account to proof address</ListItem>
                                                                        <ListItem>Address information (State, city, street, postal code)</ListItem>
                                                                    </UnorderedList>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            isVerified("residential-address") ?
                                                                <button className='text-xs text-green-600 dark:text-green-500 flex items-center gap-1'>
                                                                    <BsCheckCircle />
                                                                    <span>Completed</span>
                                                                </button> :
                                                                <button onClick={() => navigate("/onboarding/verify-residential-address")} className='text-sm text-yellow-600 dark:text-yellow-500'>update</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default KYC