import React, { useEffect, useMemo, useState } from 'react'
import ThemeButton from '@/components/ThemeButton';
import { RiSendPlaneFill, RiUser4Line } from "react-icons/ri";
import { HiMiniWallet } from "react-icons/hi2";
import WalletSlider from '@/components/WalletSlider';
import { useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { ITransaction } from '@/types/global.types';
// import { handleResponseError } from '@/utils/errors';
import { Skeleton } from '@chakra-ui/react';
import classNames from 'classnames';
import { currencyFormat, formatDateCustom } from '@/utils/helpers';
import { walletConfig } from '@/utils/constants';
import { BsViewList } from 'react-icons/bs';
import { useTheme } from '@/contexts/themeProvider';

function Dashboard() {

  // const toast = useToast();
  const navigate = useNavigate();
  const {user} = useApp();  
  const { resolvedTheme } = useTheme();

  const [activeWallet, setActiveWallet] = useState("ngn");
  const [isFetching, setIsFetching] = useState(false);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  async function getTransactions() {
    setIsFetching(true)
    try {
      const resp = await http(`wallet/transactions`, { currency: activeWallet, limit: "10" });
      resp.data ? setTransactions(resp.data as any) : setTransactions([]);
      // resp && setTransactions([
      //   {
      //     "total_amount": "50000",
      //     "timestamp": "2023-11-28 17:22:14",
      //     "status": "Successful",
      //     "reference_code": "fc102aa6f3c4a17288e7682b9c2df7c8",
      //     "balance_before": "9950000",
      //     "balance_after": "9900000",
      //     "type": "Debit",
      //     "remark": "",
      //     "attach": {
      //       "number": "0126069407",
      //       "name": "Bakare Abdulquadry Olajide",
      //       "bank": "Gtbank Plc",
      //       "code": "000013",
      //       "reference": "3060"
      //     }
      //   }
      // ])
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      // handleResponseError(error, toast);
    }
  }

  const getCurrencyIcon = useMemo(() => {
    return walletConfig.find((e) => e.currency === activeWallet)?.icon;
  }, [activeWallet])

  function shortenName(name: string) {
    if (name.length === 0) return name;
    const s = name.split(" ");
    if (s.length < 1) return name;
    return s[0];
  }

  useEffect(() => {
    getTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWallet])

  return (
    <>
      <div className='bg-purple-950 dark:bg-inherit bg-opacity-5 md:bg-inherit pt-5'>

        <div className=''>
          <div className='flex justify-between items-center mb-[50px] px-5'>
            <div className='font-extrabold text-[30px] hidden md:inline-block'>Overview</div>

            <div className='flex items-center justify-between gap-3 w-full md:w-auto '>

              <div className='flex items-center gap-3'>
                <div className='w-[40px] h-[40px] flex justify-center items-center bg-orange-200 dark:bg-orange-500 bg-opacity-45 dark:bg-opacity-15 rounded-full md:hidden'>
                  <RiUser4Line />
                </div>
                <div>
                  <div className='text-sm font-extrabold md:font-normal'>Hi, {user?.firstname}</div>
                  <div className='text-sm'>welcome</div>
                </div>
              </div>

              <div className='w-[40px] h-[40px] hidden md:flex justify-center items-center bg-orange-200 dark:bg-orange-500 bg-opacity-45 dark:bg-opacity-15 rounded-full'>
                <RiUser4Line />
              </div>
              <div className='inline-block md:hidden'>
                <ThemeButton />
              </div>
            </div>
          </div>

          <div className='w-full mb-[38px] px-5'>

            <WalletSlider onSliderChange={(e) => setActiveWallet(e)} size='normal' />
            
          </div>

          <div className='flex justify-center mb-[38px] px-5'>
            <div className='grid grid-cols-3 items-center gap-[35px]'>
              <button className='cursor-pointer' onClick={() => navigate(`/u/wallet`)}>
                <center>
                  <div className='inline-block justify-center items-center rounded-full'>
                    <div className=''>
                      <img src="/assets/icons/wallet-icon.svg" className='w-[43px] h-[43px] mb-1' alt="" />
                    </div>
                  </div>
                  <div className='text-[12px] font-bold'>Wallets</div>
                </center>
              </button>

              <button>
                <center>
                  <div className='inline-block justify-center items-center rounded-full'>
                    <div className=''>
                      <img src="/assets/icons/credit-card-icon.png" className='w-[39px] h-[39px] mb-2' alt="" />
                    </div>
                  </div>
                  <div className='text-[12px] font-bold'>Cards</div>
                </center>
              </button>

              <button>
                <center>
                  <div className='inline-block justify-center items-center rounded-full'>
                    <div className=''>
                      <img src="/assets/icons/market-icon.svg" className='w-[47px] h-[47px]' alt="" />
                    </div>
                  </div>
                  <div className='text-[12px] font-bold'>Marketplace</div>
                </center>
              </button>
            </div>
          </div>

          <div className='px-5 py-9 border-t-4 dark:border-t-purple-300 border-t-purple-300 border-opacity-20 dark:border-opacity-5 rounded-t-3xl bg-[rgb(245,245,245)] dark:bg-neutral-900'>
            <div className='flex items-center justify-between px-5 mb-8'>
              <div className='font-bold'>Recent Transaction</div>
              <button onClick={() => navigate(`/u/transactions?wallet=${activeWallet}`)} className='text-purple-950 dark:text-purple-600 text-sm font-bold'>View All</button>
            </div>

            <div className='px-5'>
            {!isFetching ?
              transactions.length > 0 ?
                transactions.map((t, i) => (
                  <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                    <div className='flex gap-3 items-center'>
                      <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                        {t.type === "Debit" ? <RiSendPlaneFill className="w-6 h-6" /> : <HiMiniWallet className="w-6 h-6" />}
                      </div>
                      <div>
                        <div className='font-bold text-sm'>{t.type === "Debit" ? `Transfer to ${shortenName(t.attach.name)}` : `Wallet Funding`}</div>
                        <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>{formatDateCustom(t.timestamp)}</div>
                      </div>
                    </div>
                    <div>
                      <div className={classNames('text-right font-bold text-sm', {
                        'text-green-600': t.type === "Credit",
                        'text-red-600': t.type === "Debit"
                      })}>{t.type === 'Debit' ? '-' : '+'} {currencyFormat(t.total_amount, getCurrencyIcon)}</div>
                      <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>
                        {
                          t.type === "Debit" ? "Wallet payment" : "Funding"
                        }
                      </div>
                    </div>
                  </div>
                )) :
                <div className='w-full h-[250px] flex justify-center px-5 items-center'>
                  <div className='text-slate-900 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70 font-light'>
                    <center>
                      <div className='mb-5 text-lg'>No record</div>
                      <BsViewList className='text-[30px]' />
                    </center>
                  </div>
                </div>
              :
              Array.from(Array(5)).map((_, i) => (
                <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                  <div className='flex gap-3 items-center'>
                    <Skeleton
                      startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                      endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                      w={'50px'} h={'50px'} borderRadius={'10%'} />
                    <div>
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'150px'} h={'14px'} mb={1} borderRadius={'10%'} />
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'60px'} h={'10px'} mb={1} borderRadius={'10%'} />
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'80px'} h={'10px'} mb={1} borderRadius={'10%'} />
                    </div>
                  </div>
                  <div>
                    <Skeleton
                      startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                      endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                      w={'40px'} h={'10px'} mb={1} borderRadius={'10%'} />
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </div>

      </div>

    
    </>
  )
}

export default Dashboard