import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <>
    <div className='bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100 font-container h-lvh w-full flex justify-center items-center'>
      <div className='mt-[-100px]'>
        <center>
          <img src="/assets/notfound2.svg" className='w-[300px] mb-10' alt="" />
          <Link to={"/u/dashboard"} className='text-purple-800 dark:text-purple-600'>Dashboard</Link>
        </center>
      </div>
    </div>
    </>
  )
}

export default NotFound