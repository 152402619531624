'use client'

import React, { useEffect, useState } from 'react'
import ThemeButton from '../ThemeButton';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { BsExclamationCircle } from 'react-icons/bs';
import { useApp } from '@/contexts/appContext';

function AuthLayout() {

    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(false);
    const { onlineStatus } = useApp();

    useEffect(() => {
        const tk = localStorage.getItem('op-app-tkn');
        if (tk) {
            navigate('/u/dashboard', { replace: true });
        } else {
            setIsFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                onlineStatus === "offline" &&
                <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-red-900 relative z-[9999] text-neutral-100'>
                    <div className='flex items-center gap-2'>
                        <BsExclamationCircle />
                        <div className='text-xs'>You are offline. kindly check internet connection</div>
                    </div>
                    {/* <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link> */}
                </div>
            }
            {isFetching ? <Loader /> :
                <div className='w-full bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-100 pt-3'>
                    <div className='w-full min-h-screen bg-neutral-50 dark:bg-neutral-900 font-container'>
                        <div>
                            <div className='w-full flex justify-between gap-5 px-7 md:px-7 pt-10 pb-1'>
                                <img src="/logo.png" className='w-[60px] mb-6' alt="logo" />
                                <div className='mt-3'>
                                    <ThemeButton />
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>}
        </>
    )
}

export default AuthLayout