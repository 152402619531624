import React from 'react'
import { useNavigate } from 'react-router-dom'

function Pay() {

  const navigate = useNavigate();
  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Send and Receive payment</div>
        </div>

        <div className='px-6'>
          <div className='w-full lg:w-[800px]'>

            <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-5'>

              <div onClick={() => navigate('/u/pay/send')} className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/send-img.svg" className='w-[200px] bottom-[-40px] right-[-10px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>Send</div>
                      <div className='text-xs'>Send funds to orion pay and external accounts in multiple currencies</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

              <div onClick={() => navigate('/u/pay/receive')} className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/wallet-pay2.svg" className='w-[200px] bottom-[-10px] right-[-10px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>Receive</div>
                      <div className='text-xs'>Receive funds into your orion pay wallet in multiple currencies</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

              <div onClick={() => navigate('/u/wallet')} className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg lg:mt-3'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/credit-img4.svg" className='w-[250px] bottom-[-10px] right-[-50px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>Fund</div>
                      <div className='text-xs'>Fund your orion pay wallet using various methods (Card, bank transfer, etc)</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

            </div>            
          </div>
        </div>

        <div className='py-8'></div>
      </div>
    </>
  )
}

export default Pay