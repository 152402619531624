import { useApp } from "@/contexts/appContext";
import { BsExclamationCircle } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa6"
// import ThemeButton from "./ThemeButton"

function SplashLoader() {

    const { onlineStatus } = useApp();
    return (
        <>
        {
            onlineStatus === "offline" &&
            <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-red-900 relative z-[9999] text-neutral-100'>
                <div className='flex items-center gap-2'>
                    <BsExclamationCircle />
                    <div className='text-xs'>You are offline. kindly check internet connection</div>
                </div>
                {/* <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link> */}
            </div>
        }
            <div>
                <div className='fixed top-0 left-0 flex justify-center pt-[50px] md:pt-[50px] w-full z-50 bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100'>
                    <div className='w-full'>

                        <div className='text-right px-10 md:mb-[200px]'>
                            {/* <ThemeButton /> */}
                        </div>

                        <div className='flex justify-center mt-[100px] mb-[70px]'>
                            <img src="/logo.png" className='w-[100px] md:w-[!60px]' alt="" />
                        </div>

                        <div className='flex justify-center flex-1'>
                            <div className='px-10'>
                                <center>
                                    <div className='font-extrabold text-xl md:text-2xl mb-10'>
                                        Future of&nbsp;
                                        <span className='text-[rgb(94,23,235)]'>Global</span>
                                        <br />
                                        <span className='text-[rgb(216,147,20)]'>Payment</span>
                                    </div>
                                    <div className="mb-10"><FaSpinner className="animate-spin" /></div>
                                    {/* <div className='mb-5'>Complete kyc to unlock wallet and other features</div> */}
                                    {/* <button className='text-neutral-100 bg-purple-700 px-10 py-2 rounded-xl'>Get started</button> */}
                                </center>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SplashLoader