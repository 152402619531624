import { useApp } from '@/contexts/appContext';
import { walletList } from '@/utils/constants';
import { useToast } from '@chakra-ui/react';
import React, { useMemo } from 'react'
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from 'react-router-dom';

function PaySend() {

  const navigate = useNavigate();
  const toast = useToast();
  const { user, wallets } = useApp();

  const [searchParams] = useSearchParams();
  const urlWallet = useMemo(() => {
      const w = searchParams.get('wallet');
      if (w) {
          if (walletList.includes(w)) {
              return w
          } else {
              return "ngn";
          }
      } else {
          return "ngn";
      }
  }, [searchParams]);

  function handleNavigate(url: string) {
      const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
      if (getBvnKycInfo.status === "Pending") {
          toast({
              title: 'Error',
              description: 'Complete kyc to continue',
              status: 'warning',
              duration: 5000,
              isClosable: true,
              position: 'top'
          })
      } else {
          if (!wallets.map(e => e.currency.toLowerCase()).includes(urlWallet)) {
              toast({
                  title: 'Error',
                  description: `Kindly create ${urlWallet.toUpperCase()} wallet`,
                  status: 'warning',
                  duration: 5000,
                  isClosable: true,
                  position: 'top'
              })
          } else {
              navigate(url);
          }
      }

  }

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='flex items-center gap-5'>
            <button className='' onClick={() => navigate(-1)}>
              <IoArrowBackCircleOutline className="w-8 h-8" />
            </button>
            <div className='font-extrabold text-xl'>Send</div>
          </div>
        </div>

        <div className='px-6'>
          <div className='w-full lg:w-[800px]'>

            <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-5'>

              <div onClick={() => handleNavigate(`/u/pay/send/wallet?wallet=${urlWallet}`)} className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/send-img2.svg" className='w-[200px] bottom-[-40px] right-[-10px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>To wallet</div>
                      <div className='text-xs'>Send funds to orion pay user</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

              <div onClick={() => handleNavigate(`/u/pay/send/bank?wallet=${urlWallet}`)} className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/globe.svg" className='w-[200px] bottom-[-110px] right-[0px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>To Bank</div>
                      <div className='text-xs'>Send to external bank accounts</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

              {/* <div className='w-full h-[150px] bg-slate-200 dark:bg-neutral-800 relative overflow-hidden z-0 cursor-pointer border-2 border-yellow-600 border-opacity-0 transition duration-300 hover:border-opacity-100 rounded-lg lg:mt-3'>
                <div className='w-[332px] h-[332px] absolute top-[-172px] left-[176px] opacity-[20%] bg-purple-600 rounded-full'></div>
                <img src="/assets/credit-img4.svg" className='w-[250px] bottom-[-10px] right-[-50px] absolute' alt="" />
                <div className='grid grid-cols-2 h-[inherit] relative'>
                  <div className='h-[inherit] px-3 md:px-8 flex items-center'>
                    <div>
                      <div className='font-extrabold text-lg'>Fund</div>
                      <div className='text-xs'>Fund your orion pay wallet using various methods</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div> */}

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>
    </>
  )
}

export default PaySend