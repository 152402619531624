import CustomInput from '@/components/CustomInput';
import { useApp } from '@/contexts/appContext'
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa6';

function Profile() {

  const toast = useToast();
  const { user } = useApp();

  const [updateEmailInput, setUpdateEmailInput] = useState(user ? user.email : "");
  const [updatePhoneInput, setUpdatePhoneInput] = useState(user ? user.phone : "");

  const [isFetching, setIsFetching] = useState(false);

  async function updatePhone() {
    setIsFetching(true)
    try {
      const resp = await http(`user/account/update/phone`, { country_code: "ng", phone: updatePhoneInput });
      setUpdatePhoneInput("")
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      handleResponseError(error, toast);
    }
  }

  async function updateEmail() {
    setIsFetching(true)
    try {
      const resp = await http(`user/account/update/email`, { email: updateEmailInput });
      setUpdateEmailInput("")
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-8 px-6'>
          <div className='font-extrabold text-xl'>My Account</div>
        </div>

        <div className='px-6 w-full md:w-[550px] mb-8'>

          <div className=' flex justify-center mb-[60px]'>
            <div>
              <center><div className='w-[80px] h-[80px] bg-slate-300 dark:bg-neutral-800 rounded-full'></div></center>
              <div>
                <center>{user?.firstname} {user?.middlename} {user?.lastname}</center>
                <center>
                  <div className='flex justify-center items-center gap-2'>
                    <div className='text-xs'>Referral code: </div>
                    <div className='text-sm'>{user?.referral_code}</div>
                  </div>
                </center>
              </div>
            </div>
          </div>

          {/* <div className='grid grid-cols-2 gap-8 mb-8'>
            <div>
              <div className='text-sm mb-1'>Firstname</div>
              <div><input value={user?.firstname} type="text" className='px-4 py-2 w-full bg-slate-300 bg-opacity-45 dark:bg-neutral-700 dark:bg-opacity-75' placeholder='Firstname' /></div>
            </div>

            <div>
              <div className='text-sm mb-1'>Lastname</div>
              <div><input value={user?.lastname} type="text" className='px-4 py-2 w-full bg-slate-300 bg-opacity-45 dark:bg-neutral-700 dark:bg-opacity-75' placeholder='Lastname' /></div>
            </div>
          </div> */}

          <div className='mb-8'>
            <div className='mb-3 text-sm font-bold border-b-4 inline-block pb-1'>Update Email</div>

            <div className='mb-5'>
              <div className='text-sm mb-1'>Email</div>
              <div>
                <CustomInput
                  type='email'
                  name='email'
                  value={updateEmailInput}
                  onChange={(e) => setUpdateEmailInput(e)}
                  placeholder=''
                  disabled={isFetching}
                />
              </div>
            </div>

            <button onClick={() => updateEmail()} disabled={isFetching || updateEmailInput.length === 0} className='justify-center flex w-[200px] text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
              {
                isFetching ?
                  <FaSpinner className='animate-spin' /> :
                  <span>Submit</span>
              }
            </button>
          </div>

          <div>
            <div className='mb-3 text-sm font-bold border-b-4 inline-block pb-1'>Update Phone</div>

            <div className='mb-5'>
              <div className='text-sm mb-1'>Phone</div>
              <div>
                <CustomInput
                  type='text'
                  name='phone'
                  value={updatePhoneInput}
                  onChange={(e) => setUpdatePhoneInput(e)}
                  placeholder=''
                  disabled={isFetching}
                />
              </div>
            </div>

            <button onClick={() => updatePhone()} disabled={isFetching || updatePhoneInput.length === 0} className='justify-center flex w-[200px] text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
              {
                isFetching ?
                  <FaSpinner className='animate-spin' /> :
                  <span>Submit</span>
              }
            </button>
          </div>

        </div>
      </div>
      <div className='py-8'></div>
    </>
  )
}

export default Profile