import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
// import { sleep } from '@/utils/helpers';
import { PinInput, PinInputField, useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

function OnboardVerifyPhone() {

    const [tab, setTab] = useState<1 | 2>(1);
    const [otp, setOtp] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingOtp, setIsFetchingOtp] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const { logout, setShowOnboard, setUser, user } = useApp();

    async function verifyPhone() {
        setIsFetching(true);
        try {
            const verifyResp = await http("user/verification/process/phone", { mode: "verify_otp", otp });
            console.log("phone verify response:", verifyResp);
            setUser((u) => ({ ...u!, kyc: { ...u!.kyc, auth: { ...u!.kyc.auth, phone_verified: "Yes" } } }));
            setTab(2);
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function resendOtp() {
        setIsFetchingOtp(true);
        try {
            const verifyResp = await http("user/verification/process/phone", { mode: "send_otp" });
            console.log("phone verify response:", verifyResp);
            // const verifyMsg = verifyResp.message.split(" ");
            // setOtp(verifyMsg[verifyMsg.length - 1]);
            // alert(verifyMsg[verifyMsg.length - 1])
            toast({
                title: 'Success',
                description: verifyResp.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            setIsFetchingOtp(false)
        } catch (error) {
            console.log(error);
            setIsFetchingOtp(false);
            handleResponseError(error, toast);
        }
    }

    async function continueClicked() {
        if (user && user.email.length > 0 && user.kyc.auth.email_verified === "No") {
            await http("user/verification/process/email", {mode: "send_otp"});
            setShowOnboard("email");
            navigate('/onboarding/verify-email');
        } else {
            setShowOnboard("bvn");
            navigate('/onboarding/verify-bvn');
        }
    }

    return (
        <>
            <div>
                <div className='fixed h-lvh overflow-auto top-0 left-0 flex justify-center pt-[50px] md:pt-[120px] w-full z-50 bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100'>
                    <div className='w-full md:w-[500px]'>
                        {tab === 1 && <div className='text-right px-8'>
                            <button onClick={logout} className='text-purple-800 dark:text-purple-400 text-sm'>Log out</button>
                        </div>}
                        {tab === 1 ?
                            <div className='mt-10'>

                                <center>
                                    <div className='mb-5 font-extrabold text-2xl'>
                                        Verify <br /> Phone Number
                                    </div>

                                    <div className='mb-3'>
                                        <img src="/assets/message-img2.svg" className='w-[150px] md:w-[200px] h-[150px] md:h-[200px]' alt="" />
                                    </div>

                                    <div className='mb-8 w-full'>
                                        <PinInput onChange={(e) => setOtp(e)} onComplete={verifyPhone} value={otp}>
                                            <PinInputField className='mr-2' />
                                            <PinInputField className='mr-2' />
                                            <PinInputField className='mr-2' />
                                            <PinInputField className='mr-2' />
                                            <PinInputField className='mr-2' />
                                            <PinInputField className='' />
                                        </PinInput>
                                    </div>

                                    <div className='mb-8 px-3'>
                                        <div className='mb-3 font-extrabold text-xl'>We just sent you an OTP</div>
                                        <div className='text-sm'>Please enter the code we sent to your phone number</div>
                                    </div>

                                    <div className='mb-10'>
                                        <div><button className={classNames('text-purple-600 dark:text-purple-400', {
                                            'dark:text-opacity-50 text-opacity-50': isFetching,
                                            'dark:text-opacity-1000 text-opacity-100': !isFetching
                                        })} onClick={() => verifyPhone()} disabled={isFetching}>
                                            {
                                                isFetching ?
                                                    <FaSpinner className='animate-spin' /> :
                                                    <span>Verify phone number</span>
                                            }
                                        </button></div>
                                    </div>

                                    <div className='inline-block'>
                                        <div className='mb-5 flex gap-3 text-xs'>
                                            <div>Didn't get a code ? </div>
                                            <button className='text-purple-600 dark:text-purple-400' onClick={() => resendOtp()} disabled={isFetchingOtp}>
                                                {
                                                    isFetchingOtp ?
                                                        <FaSpinner className='animate-spin' /> :
                                                        <span>resend</span>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                    <div className='py-6'></div>

                                </center>
                            </div> :

                            <div className='mt-16'>

                                <center>
                                    <div className='mb-3'>
                                        <img src="/assets/verified-img.svg" className='w-[220px] h-[220px]' alt="" />
                                    </div>

                                    <div className='mb-8 px-3'>
                                        <div className='mb-4 font-extrabold text-xl'>Phone Verified</div>
                                        {/* <div className='text-sm'>Welcome to Orion pay, where you can send funds across the world in minutes!</div> */}
                                        
                                        <div className='text-sm'>You have successfully verified your account phone number. Let's continue</div>
                                    </div>

                                    <div className='mb-5'>
                                        <div><button className='text-purple-600 dark:text-purple-400' onClick={() => continueClicked()}>Continue</button></div>
                                    </div>
                                </center>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardVerifyPhone