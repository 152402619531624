import React from 'react'

function Support() {
  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Support</div>
        </div>

        <div className='px-6'>
          <div className='font-extrabold mb-3 '>Tell us how we can help 👋</div>
          <div className='mb-10'>Our super trained and compentent team <br /> are
            standing by for services and support.</div>

          <button className='px-6 py-2 text-sm bg-purple-700 rounded-xl text-white'>Start chat</button>
        </div>
      </div>
    </>
  )
}

export default Support