'use client'

import React, { useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { useApp } from '@/contexts/appContext';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { sleep } from '@/utils/helpers';
import http from '@/lib/http';
import { PinInput, PinInputField } from '@chakra-ui/react'
import CustomInput from '@/components/CustomInput';
import { IUser } from '@/types/user.types';
import { FaSpinner } from 'react-icons/fa6';


function Register() {

    const [tab, setTab] = useState<1 | 2 | 3>(1);

    const [registerInput, setRegisterInput] = useState({
        country_code: "NG",
        phone: "",
        email: "",
        pin: "",
        referrer: ""
    });
    const [confirmPinInput, setConfirmPinInput] = useState("");

    const [isFetching, setIsFetching] = useState(false);

    const { setShowOnboard, setUser, setIsAuthenticated } = useApp();
    const navigate = useNavigate();
    const toast = useToast();

    async function signup() {
        setIsFetching(true);
        console.log(registerInput)
        try {
            await http<any>("user/registration/email-phone-pin", { ...registerInput, phone: `+234${registerInput.phone}` }, process.env.REACT_APP_PUBLIC_KEY);
            const loginResp = await http<any>("user/login/phone-pin", { phone: `+234${registerInput.phone}`, pin: registerInput.pin }, process.env.REACT_APP_PUBLIC_KEY);
            // const userResp = await http("user/account/details", {}, loginResp.data!.token);
            const _user = (loginResp.data as IUser | undefined);
            if (_user) {
                const _kycDetails = _user.kyc.details;

                const getBvnKyc = _kycDetails.find((e) => e.parameter === "bvn");
                if (!getBvnKyc) throw new BadRequestError("Authentication failed: try again later");

                const verifyResp = await http("user/verification/process/phone", { mode: "send_otp" }, _user.token);
                console.log("verifyResp:", verifyResp);
                setShowOnboard("phone");

                setUser(_user)
                localStorage.setItem('op-app-tkn', _user.token!);
                setIsAuthenticated(true);
                console.log("logged in user:", _user)
                navigate("/onboarding/verify-phone");
            } else {
                throw new BadRequestError("Authentication failed: invalid response");
            }
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function authenticateSignUp() {
        setIsFetching(true);
        await sleep(1);
        setTab(3);
        setIsFetching(false);
    }

    const isInputsValid = useMemo(() => {
        return registerInput.phone.length > 0 && registerInput.email.length > 0 && registerInput.pin.length >= 6 && !isFetching && registerInput.pin === confirmPinInput;
    }, [registerInput, isFetching, confirmPinInput])

    return (
        <>

            <div className='px-6'>
                <div className='block md:flex w-full justify-center mt-0 md:mt-[50px]'>
                    <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-2 py-2 md:px-[60px] md:py-[80px] w-full lg:w-[70%] rounded-xl'>

                        <div className='text-black dark:text-neutral-100 '>

                            <div className='block md:flex justify-center w-full'>
                                <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                                    <div className='mb-3'>
                                        <div className='flex justify-between items-center'>

                                            {tab === 2 ?
                                                <div className=''>
                                                    <button className='flex items-center gap-2' onClick={() => setTab(1)}>
                                                        <IoArrowBackCircleOutline className="w-8 h-8" />
                                                        <div className='font-extrabold text-sm'>Back</div>
                                                    </button>
                                                </div>
                                                :
                                                <div></div>
                                            }

                                            {/* <div className='flex gap-3 items-center'>
                                                <div className='text-[13px]'>{tab}/3</div>
                                                <div className='flex items-center gap-2'>
                                                    <FaCircle className={classNames('text-[10px]', {
                                                        'text-purple-500': tab >= 1
                                                    })} />
                                                    <FaCircle className={classNames('text-[10px]', {
                                                        'text-purple-500': tab >= 2
                                                    })} />
                                                    <FaCircle className={classNames('text-[10px]', {
                                                        'text-purple-500': tab >= 3
                                                    })} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    {tab === 1 ?
                                        <div>
                                            <div className='mb-8'>
                                                <div className='mb-3 font-extrabold text-xl'>Welcome to Orion pay</div>
                                                <div>Already have an account? <Link className='text-purple-600 dark:text-purple-400' to={'/auth/login'}>Log in</Link></div>
                                            </div>

                                            {/* <div className='mb-10 px-0'>
                                                <div className='flex justify-between'>
                                                    <div className='flex items-center gap-2'>
                                                        <button onClick={() => changeTab("phone")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                                            'outline-none outline-3 outline-purple-700': methodTab === "phone",
                                                            '': methodTab !== "phone"
                                                        })}>
                                                            <div className='md:pr-0'>Phone</div>
                                                        </button>
                                                        <button onClick={() => changeTab("email")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                                            'outline-none outline-3 outline-purple-700': methodTab === "email",
                                                            '': methodTab !== "email"
                                                        })}>
                                                            <div className='md:pr-0'>Email</div>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-1'><span className='text-red-400'>*</span> Phone number</div>
                                                <CustomInput
                                                    type='number'
                                                    name='phone'
                                                    value={registerInput.phone}
                                                    onChange={(e) => setRegisterInput((i) => ({ ...i, phone: e }))}
                                                    placeholder='Phone'
                                                    icon={'+234'}
                                                    disabled={isFetching}
                                                    ariaAutocomplete='both'
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-1'><span className='text-red-400'>*</span> Email</div>
                                                <CustomInput
                                                    type='email'
                                                    name='email'
                                                    value={registerInput.email}
                                                    onChange={(e) => setRegisterInput((i) => ({ ...i, email: e }))}
                                                    placeholder='Email'
                                                    disabled={isFetching}
                                                    ariaAutocomplete='both'
                                                />
                                            </div>


                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-1'><span className='text-red-400'>*</span> PIN</div>
                                                <CustomInput
                                                    type='password'
                                                    value={registerInput.pin}
                                                    onChange={(e) => setRegisterInput((i) => ({ ...i, pin: e }))}
                                                    placeholder='******'
                                                    disabled={isFetching}
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-1'><span className='text-red-400'>*</span> Confirm PIN</div>
                                                <CustomInput
                                                    type='password'
                                                    value={confirmPinInput}
                                                    onChange={(e) => setConfirmPinInput(e)}
                                                    placeholder='******'
                                                    disabled={isFetching}
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-1'>Referral Code</div>
                                                <CustomInput
                                                    type='text'
                                                    name='referral_code'
                                                    value={registerInput.referrer}
                                                    onChange={(e) => setRegisterInput((i) => ({ ...i, referrer: e }))}
                                                    placeholder='Referrer'
                                                    disabled={isFetching}
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                <div className='flex gap-3 items-center px-1'>
                                                    {/* <input type="checkbox" className='w-4 h-4' /> */}
                                                    <div className='text-sm'>
                                                        By continuing, you agree to Orion Pay <Link to={'https://orionpay.africa/terms-and-conditions/'} target='_blank'><span className='text-purple-500'>Terms</span></Link> and <Link to='https://orionpay.africa/privacy-policy/' target='blank'><span className='text-purple-500'>Privacy Policy</span></Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mb-5'>
                                                <button onClick={() => signup()} disabled={!isInputsValid} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                                    {
                                                        isFetching ?
                                                            <div className='py-[2px]'><FaSpinner className='animate-spin w-[15px] h-[15px]' /></div> :
                                                            <span>Sign up</span>
                                                    }
                                                </button>
                                            </div>
                                        </div> :
                                        tab === 2 ?
                                            <div>

                                                <center>
                                                    <div className='mb-3'>
                                                        <img src="/assets/message-img2.svg" className='w-[150px] md:w-[200px] h-[150px] md:h-[200px]' alt="" />
                                                    </div>

                                                    <div className='mb-8 w-full'>
                                                        <PinInput>
                                                            <PinInputField className='mr-3' />
                                                            <PinInputField className='mr-3' />
                                                            <PinInputField className='mr-3' />
                                                            <PinInputField className='mr-3' />
                                                        </PinInput>
                                                    </div>

                                                    <div className='mb-8'>
                                                        <div className='mb-3 font-extrabold text-xl'>We just sent you an OTP</div>
                                                        <div className='text-sm'>Please enter the code we sent to your phone number</div>
                                                    </div>

                                                    <div className='mb-8'>
                                                        <div><button className='text-purple-600 dark:text-purple-400' onClick={() => authenticateSignUp()}>
                                                            {
                                                                isFetching ?
                                                                    <FaSpinner /> :
                                                                    <span>Verify phone number</span>
                                                            }
                                                        </button></div>
                                                    </div>

                                                    <div className='inline-block'>
                                                        <div className='mb-5 flex gap-3 text-xs'>
                                                            <div>Didn't get a code ? </div>
                                                            <button className='text-purple-600 dark:text-purple-400'>resend</button>
                                                        </div>
                                                    </div>
                                                </center>
                                            </div> :

                                            <div>

                                                <center>
                                                    <div className='mb-3'>
                                                        <img src="/assets/verified-img2.svg" className='w-[200px] h-[200px]' alt="" />
                                                    </div>

                                                    <div className='mb-8'>
                                                        <div className='mb-3 font-extrabold text-xl'>Phone Verified</div>
                                                        <div className='text-sm'>Now, you can send funds across the world in minutes!</div>
                                                    </div>

                                                    <div className='mb-5'>
                                                        <div><button className='text-purple-600 dark:text-purple-400' onClick={() => navigate('/onboarding/verify-bvn')}>Continue</button></div>
                                                    </div>
                                                </center>
                                            </div>
                                    }

                                    <div className='py-8 md:py-0'></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='py-0 md:py-10'></div>
        </>
    )
}

export default Register