import classNames from 'classnames'
import React, { useMemo, useState } from 'react'

function CustomSelect({
    value,
    onChange,
    options,
    placeholder = 'Select',
    disabled = false,
    display
}: {
    value: string,
    onChange: (e: string) => void,
    options: any[],
    disabled?: boolean
    placeholder?: string,
    display?: {
        title: string,
        body?: string,
        footer?: string,
        value: string
    }
}) {

    const [isOpen, setIsOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    function onSelect(data: any) {
        onChange(display ? data[display.value] : data);
        setIsOpen(false);
        setSearchInput("");
    }

    const filteredOptions = useMemo(() => {
        if (searchInput.length === 0) return options;
        if (options.length === 0) return options;
        return options.filter((e) => {
            if (display) {
                return `${e[display.title]}`.toLowerCase().includes(searchInput.toLowerCase());
            } else {
                return `${e}`.toLowerCase().includes(searchInput.toLowerCase());
            }
        })
    }, [searchInput, options, display]);

    return (
        <>
            <div className='relative'>
                <button onClick={() => { !disabled && setIsOpen((e) => e ? false : true) }}
                    className={classNames(`text-left relative w-full text-sm 
                        focus:border-slate-800 dark:focus:border-neutral-400 border-slate-300 dark:border-neutral-600
                        shadow-sm px-5 py-4 rounded-lg border-2 placeholder:text-neutral-500 dark:placeholder:text-neutral-400 cursor-pointer`, {
                        'bg-slate-200 dark:bg-neutral-700 dark:bg-opacity-60': disabled,
                        'bg-slate-50 dark:bg-neutral-700': !disabled
                    })}
                >
                    {
                        value ?
                            display && filteredOptions.find((e) => e[display.value] === value) ?
                                <>
                                    <div className={`text-sm ${display && display.body && 'font-bold'}`}>
                                        {filteredOptions.find((e) => e[display.value] === value)[display.title]}
                                    </div>
                                    {display && display.body && <div className='text-sm text-slate-800 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70'>
                                        {filteredOptions.find((e) => e[display.value] === value)[display.body]}
                                    </div>}
                                    {display && display.footer && <div className='text-sm text-slate-800 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70'>
                                        {filteredOptions.find((e) => e[display.value] === value)[display.footer]}
                                    </div>}
                                </>
                                : value :
                            placeholder
                    }
                </button>
                {isOpen &&
                    <div className='-mt-1 z-[1] absolute shadow-lg bg-slate-200 w-full dark:bg-neutral-800 px-2 py-4 rounded-lg border-2 border-t-0 rounded-t-none border-slate-300 dark:border-neutral-600'>
                        <input type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder='Enter keyword' className='mb-2 w-full text-sm bg-slate-50 shadow-sm dark:bg-neutral-700 px-5 py-3 rounded-lg border-2 border-slate-300 dark:border-neutral-600 placeholder:text-neutral-500 dark:placeholder:text-neutral-400 ' />
                        {filteredOptions.length > 0 ?
                            <div className='max-h-[100px] overflow-auto'>
                                {
                                    filteredOptions.map((e, i) => (
                                        <button className='block w-full text-left mb-2 cursor-pointer border-none outline-none focus:bg-slate-400 focus:bg-opacity-45 dark:focus:bg-neutral-600 dark:focus:bg-opacity-70 px-2 py-1 rounded-lg'
                                            tabIndex={0}
                                            key={i}
                                            onClick={() => onSelect(e)}
                                        >
                                            <div className={`text-sm ${display && display.body && 'font-bold'}`}>
                                                {display ? e[display.title] : e}
                                            </div>
                                            {display && display.body && <div className='text-sm text-slate-800 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70'>
                                                {e[display.body]}
                                            </div>}
                                            {display && display.footer && <div className='text-sm text-slate-800 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70'>
                                                {e[display.footer]}
                                            </div>}
                                        </button>
                                    ))
                                }
                            </div> :
                            <div className='text-sm text-slate-800 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70'>-- No options --</div>
                        }
                    </div>}
            </div>
        </>
    )
}

export default CustomSelect