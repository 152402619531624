import CustomInput from '@/components/CustomInput';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { FaSpinner } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';
import { Switch } from '@chakra-ui/react'
import { useApp } from '@/contexts/appContext';

function Security() {

  const { user, setUser } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();

  const urlTab = useMemo(() => {
    const p = searchParams.get("page");
    if (!p) return "pin";
    if (["pin", "2FA"].includes(p)) {
      return p;
    } else {
      return "pin"
    }
  }, [searchParams])

  const [securityTab, setSecurityTab] = useState(urlTab);

  const toast = useToast();

  const [updatePinInput, setUpdatePinInput] = useState({
    new_pin: "",
    confirm_pin: ""
  });

  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating2fa, setIsUpdating2fa] = useState(false);

  const getLogin2faValue = useMemo<"Yes" | "No">(() => {
    if (!user) return "No";
    if (user.security.length === 0) return "No";
    const login2fa_ = user.security.find((e) => e.type === "login_2fa");
    if (!login2fa_) return "No";
    return login2fa_.value;
  }, [user])

  async function updatePin() {
    setIsFetching(true)
    try {
      const resp = await http(`user/account/update/pin`, { pin: updatePinInput.new_pin });
      setUpdatePinInput({
        new_pin: "",
        confirm_pin: ""
      })
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      handleResponseError(error, toast);
    }
  }

  function changeTab(tab: any) {
    setSecurityTab(tab);
    setSearchParams({ page: tab });
  }

  async function update2fa() {
    setIsUpdating2fa(true)
    try {
      const val = getLogin2faValue === "No" ? "Yes" : "No";
      const resp = await http(`user/account/update/enable-security`, { login_2fa: val });
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setUser((u) => ({
        ...u!,
        security: u!.security.find((e) => e.type === "login_2fa") ?
          [...u!.security.filter((f) => f.type !== "login_2fa"), { type: "login_2fa", value: val }] :
          [...u!.security, { type: "login_2fa", value: val }]
      }))
      setIsUpdating2fa(false)
    } catch (error) {
      console.log(error);
      setIsUpdating2fa(false)
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Account security</div>
        </div>

        <div className='mb-10 px-6'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              <button onClick={() => changeTab("pin")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': securityTab === "pin",
                '': securityTab !== "pin"
              })}>
                {/* <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" />  */}
                <div className='md:pr-0'>Change PIN</div>
              </button>
              <button onClick={() => changeTab("2FA")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': securityTab === "2FA",
                '': securityTab !== "2FA"
              })}>
                {/* <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> */}
                <div className='md:pr-0'>2FA</div>
              </button>

            </div>
          </div>
        </div>

        {
          // Password tab
          securityTab === "pin" ?
            <div className='mb-5 w-full md:w-[550px] px-6 pt-2'>
              {/* <div className='mb-10'>
                                <div className='flex mb-2'>
                                    <div className='text-sm font-bold'>Perks :</div>
                                </div>

                                <div className='mb-5'>
                                    <div className='text-sm'>Limit: ₦50,000 - ₦500,000</div>
                                </div>
                            </div> */}

              <div>
                <div className='flex mb-5'>
                  <div className='text-sm font-normal'>Kindly update your PIN below.</div>
                </div>

                <div className='mb-5'>

                  <div className='mb-6'>
                    <div className='flex mb-2'>
                      <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-500 dark:text-neutral-300 '>Enter New PIN</span></div>
                    </div>
                    {/* <div className='flex mb-2 pl-2'>
                      <div className='text-[13px]'>
                        <span className='text-neutral-500 dark:text-neutral-300'>
                          A one time password will be sent to the phone number <br /> registered to the BVN entered below
                        </span>
                      </div>
                    </div> */}

                    <div>
                      <CustomInput
                        type='password'
                        name='new_pin'
                        value={updatePinInput.new_pin}
                        onChange={(e) => setUpdatePinInput(i => ({ ...i, new_pin: e }))}
                        placeholder=''
                        disabled={isFetching}
                      />
                    </div>
                  </div>

                  <div className='mb-10'>
                    <div className='flex mb-2'>
                      <div className='text-sm'><span className='text-red-400'>*</span> <span className='text-neutral-500 dark:text-neutral-300 '>Confrim PIN</span></div>
                    </div>

                    <div>
                      <CustomInput
                        type='password'
                        name='confirm_pin'
                        value={updatePinInput.confirm_pin}
                        onChange={(e) => setUpdatePinInput(i => ({ ...i, confirm_pin: e }))}
                        placeholder=''
                        disabled={isFetching}
                      />
                    </div>
                  </div>



                  <button onClick={() => updatePin()} disabled={
                    isFetching ||
                    updatePinInput.new_pin.length === 0 ||
                    updatePinInput.confirm_pin.length === 0 ||
                    updatePinInput.new_pin !== updatePinInput.confirm_pin
                  } className='justify-center flex w-[200px] text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                    {
                      isFetching ?
                        <FaSpinner className='animate-spin' /> :
                        <span>Submit</span>
                    }
                  </button>
                </div>
              </div>
            </div> :

            // 2FA tab
            <div className='mb-5 px-6 pt-1'>
              <div className='flex justify-between gap-10 items-center px-5 py-5 bg-neutral-200 dark:bg-neutral-800 rounded-lg '>
                <div className='w-[70%] md:w-[50%]'>
                  <div className='font-bold border-b-4 border-purple-700 mb-3 inline-block'>Email</div>
                  <div className='text-sm'>Enable two factor authentication for every login attempt on your account. An OTP will be sent to your account email</div>
                </div>

                <div className='flex justify-center'>
                  <Switch isChecked={getLogin2faValue === "Yes" ? true : false} onChange={() => update2fa()} isDisabled={isUpdating2fa} />
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default Security