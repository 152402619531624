
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import CustomInput from '@/components/CustomInput';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import { CgSpinner } from "react-icons/cg";

function OnboardVerifyNIN() {
    
    const toast = useToast();
    const navigate = useNavigate();
    const {user, setUser} = useApp();
    
    const [nin, setNin] = useState("");
    const [isFetching, setIsFetching] = useState(false);

    async function verifyNINKyc() {
        setIsFetching(true);
        try {
            const resp = await http("user/verification/process/nin", { nin: nin });
            const newKycDetails = [...user!.kyc.details];
            const index = newKycDetails.findIndex((k) => k.parameter === "nin");
            if (index !== -1) {
                newKycDetails[index].status = "Successful"
                setUser((u) => ({ ...u!, kyc: { ...u!.kyc, details: newKycDetails } }));
            }
            toast({
              title: 'Success',
              description: resp.message,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            navigate("/u/account/kyc?tier=2");
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div>
                <div className='overflow-auto top-0 left-0 pt-[100px] flex justify-center items-center w-full z-50 bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100'>
                    <div className='w-full md:w-[500px]'>
                        <div className='mt-[-100px]'>
                            <div className='text-right px-8 mb-2'>
                                <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={() => navigate(-1)}>Skip</button>
                            </div>
                            <div className='mb-8 pt-[30px] md:pb-[200px] px-6'>

                                <div className='mb-8'>
                                    <div className='mb-1 font-extrabold text-xl'>Verify NIN</div>
                                    <div>Enter your 11 digit National Identity Number</div>
                                </div>

                                <div className='mb-5'>
                                    <div className='text-sm font-normal mb-[5px]'> <span className='text-red-600'>*</span> NIN</div>
                                    <CustomInput
                                        type='number'
                                        name='nin'
                                        value={nin}
                                        onChange={(e) => setNin(nin)}
                                        placeholder='00000000000'
                                        disabled={isFetching}
                                    />
                                </div>

                                <div className='mb-5'>
                                    <button onClick={() => verifyNINKyc()} disabled={isFetching} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                        {
                                            isFetching ?
                                                <CgSpinner className='animate-spin' /> :
                                                <span>Verify</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='py-10'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardVerifyNIN