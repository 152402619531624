// 'use client'

import classNames from 'classnames';
import React, { useState } from 'react'
import { FiEye, FiEyeOff } from "react-icons/fi";

function CustomInput({
    value,
    onChange,
    placeholder = '',
    type = 'text',
    disabled = false,
    ariaAutocomplete = 'both',
    name = '',
    icon = ''
}: {
    value?: string,
    onChange: (e: string) => void,
    disabled?: boolean
    placeholder?: string,
    type?: 'text' | 'number' | 'email' | 'password'
    ariaAutocomplete?: "list" | "none" | "inline" | "both" | undefined,
    name?: string,
    icon?: string | JSX.Element
}) {

    const inputRef = React.createRef<HTMLInputElement>();
    const [isFocused, setIsFocused] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    return (
        <>
            <div
                className={classNames(`cursor-text flex gap-2 items-center w-full text-sm 
                ${isFocused ? 'border-slate-800 dark:border-neutral-400' : 'border-slate-300 dark:border-neutral-600'} 
                 shadow-sm px-5 py-4 rounded-lg border-2 dark:border-2 placeholder:text-neutral-500 dark:placeholder:text-neutral-400`,
                    {
                        'bg-slate-200 dark:bg-neutral-800': disabled,
                        'bg-slate-50 dark:bg-neutral-700': !disabled
                    })}
                // tabIndex={0}
                onClick={() => inputRef.current?.focus()}
            >
                {icon && <div>{icon}</div>}
                <input
                    ref={inputRef}
                    type={passwordShow ? 'text' : type}
                    value={value && value}
                    className='w-full placeholder:text-neutral-500 dark:placeholder:text-neutral-400 bg-transparent outline-none'
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    aria-autocomplete={ariaAutocomplete}
                    disabled={disabled}
                    name={name}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                // max={10}
                />
                {
                    type === "password" &&
                    <>
                        <button onClick={() => setPasswordShow((e) => e ? false : true)}>
                            {
                                !passwordShow ?
                                <FiEye /> :
                                <FiEyeOff />
                            }
                        </button>
                    </>
                }
            </div>
        </>
    )
}

export default CustomInput