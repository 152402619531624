export function setStorageItemWithExpiry(key: string, value: any, expiryTimeInSeconds: number) {
  const expiryTime = expiryTimeInSeconds * 1000; // Convert to milliseconds
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + expiryTime
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getStorageItemWithExpiry(key: string): any {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item: { value: any; expiry: number } = JSON.parse(itemStr);
  const now = new Date().getTime();
  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function upperCaseFirst(input: string): string {
  if (input.length === 0) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function numberFormat(data: string) {
  return (Number(data)).toLocaleString("en-US");
}

export function currencyFormat(data: string, currency = "₦") {
  if (parseFloat(data) === 0) return `${currency} 0.00`;
  return `${currency} ${numberFormat(data)}`
}

export function spreadArrToString(data: string[]): string {
  let s = "";
  data.forEach((e, i) => {
    if (i !== data.length - 1) {
      s += e + ", "
    } else {
      s += e
    }
  });
  return s;
}

export function formatDateCustom(dateString: string, formatType: string = "DD MM YY", includeTime = false): string {
  const date: Date = new Date(dateString);  
  const monthNames: string[] = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];  
  const pad = (number: number): string => String(number).padStart(2, "0");

  return formatType.replace(/(DD|MM|YY|hh|mm|ss)/g, (match) => {
    switch (match) {
      case "DD":
        return pad(date.getDate());
      case "MM":
        return monthNames[date.getMonth()];
      case "YY":
        return String(date.getFullYear());
      case "hh":
        return pad(includeTime ? date.getHours() : 12);
      case "mm":
        return pad(date.getMinutes());
      case "ss":
        return pad(date.getSeconds());
      default:
        return match;
    }
  });
}

export function formatDate(dateString: string, includeTime = true) {
  let dateObject = new Date(dateString);
  let date = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getDate();
  let time = dateObject.getHours() + ":" + dateObject.getMinutes() + ":" + dateObject.getSeconds();
  let dateTime = date + (includeTime ? ' ' + time : '');
  return dateTime;
}

export function formatDateNoTimezone(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  const d = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  console.log(d)
  return d;
}

export function shortenWord(word: string, maxLength: number) {
  if (word.length <= maxLength) {
    return word;
  }

  const shortenedWord = word.substring(0, maxLength - 3) + '...';
  return shortenedWord;
}

export function truncate(str: string, length = 25) {
  if (str.length < 1) return
  let truncatedString = str
  if (length > 10 && str.length > length) {
    truncatedString = str.substring(0, length - 3) + ' ...'
  }
  return truncatedString
}

export function removeUnderscore(str: string): string {
  return str.replace(/_/g, ' ');
}

export async function sleep(sec: number) {
  await new Promise((res) => {
    setTimeout(() => res(true), sec * 1000)
  });
}