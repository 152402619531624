import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Show,
    Image,
} from '@chakra-ui/react'

import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames'
import { RiBarChart2Line, RiBarChart2Fill, RiBankCardLine, RiBankCardFill } from "react-icons/ri";
import { HiDotsHorizontal, HiShieldCheck, HiUsers } from 'react-icons/hi';
import { IoWalletOutline, IoWalletSharp } from 'react-icons/io5';
import ThemeButton from './ThemeButton';
import { FaQuestionCircle, FaUserEdit } from 'react-icons/fa';
import { AiTwotoneProfile } from 'react-icons/ai';
import { FaDoorOpen } from 'react-icons/fa6';
import { useApp } from '@/contexts/appContext';

function MobileFooterTab() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {logout} = useApp();
    const { isOpen: isMoreTabModalOpen, onOpen: onMoreTabModalOpen, onClose: onMoreTabModalClose } = useDisclosure()

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    function handleMoreNavigate(url:string) {
        onMoreTabModalClose();
        navigate(url);
    }

    return (
        <>
            <div className='font-container z-50 md:hidden fixed bottom-0 left-0 w-full px-[5px] bg-neutral-100 dark:bg-neutral-900 border-t-2 border-t-neutral-300 dark:border-t-neutral-800 shadow-md'>
                <div className='flex w-full items-center justify-between'>
                    <button className={classNames('px-3 py-4', {
                        'text-purple-950 dark:text-purple-300 font-bold': getPath(2) === "wallet",
                        'text-neutral-900 dark:text-neutral-200 font-normal': getPath(2) !== "wallet"
                    })} onClick={() => navigate("/u/wallet")}>
                        <center>
                            <Icon as={getPath(2) === "wallet" ? IoWalletSharp : IoWalletOutline} w={6} h={6} />
                            <div className={classNames('text-[12px]')}>Wallet</div>
                        </center>
                    </button>

                    <button className={classNames('px-3 py-4', {
                        'text-purple-950 dark:text-purple-300 font-bold': getPath(2) === "pay",
                        'text-neutral-900 dark:text-neutral-200 font-normal': getPath(2) !== "pay"
                    })} onClick={() => navigate("/u/pay")}>
                        <center>
                            <Icon as={getPath(2) === "pay" ? RiBankCardFill : RiBankCardLine} w={6} h={6} />
                            <div className={classNames('text-[12px]')}>Send/Receive</div>
                        </center>
                    </button>

                    <div className='w-[200px]'>
                        <div className='flex justify-center'>
                            <div className='relative'>
                                <div className='absolute left-[-30px] top-[-70px]'>
                                    <button className='w-[60px]' onClick={() => navigate("/u/dashboard")}>
                                        <img src="/logo.png" alt="" className='w-[60px]' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className={classNames('px-3 py-4', {
                        'text-purple-950 dark:text-purple-300 font-bold': getPath(2) === "transactions",
                        'text-neutral-900 dark:text-neutral-200 font-normal': getPath(2) !== "transactions"
                    })} onClick={() => navigate("/u/transactions")}>
                        <center>
                            <Icon as={getPath(2) === "transactions" ? RiBarChart2Fill : RiBarChart2Line} w={'22px'} h={'22px'} />
                            <div className={classNames('text-[12px]')}>Transactions</div>
                        </center>
                    </button>

                    <button className={classNames('px-3 py-4', {
                        'text-purple-950 dark:text-purple-300': (getPath(2) !== "transactions" && getPath(2) !== "dashboard" && getPath(2) !== "wallet" && getPath(2) !== "pay"),
                        'text-neutral-900 dark:text-neutral-200 font-normal': (getPath(2) === "transactions" || getPath(2) === "dashboard" || getPath(2) === "wallet" || getPath(2) === "pay")
                    })} onClick={() => onMoreTabModalOpen()}>
                        <center>
                            <Icon as={getPath(2) === "more" ? HiDotsHorizontal : HiDotsHorizontal} w={6} h={6} />
                            <div className={classNames('text-[12px] ')}>More</div>
                        </center>
                    </button>

                </div>
            </div>

            <Show below='md'>
                <Drawer placement={"right"} isOpen={isMoreTabModalOpen} onClose={onMoreTabModalClose} closeOnOverlayClick={true}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton className='text-neutral-800 dark:text-white mt-[10px]' />
                        <DrawerBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900 font-container'>
                            <div className='w-full'>

                                <div className='px-4 mt-[60px] mb-[20px] flex justify-between items-center'>
                                    <Image src={'/logo.png'} className='w-[46px]' alt='logo' />
                                    <div>
                                        <ThemeButton />
                                    </div>
                                </div>

                                <div className=' md:h-auto md:pt-6 w-full pb-[100px]'>


                                    <button onClick={() => handleMoreNavigate(`/u/beneficiaries`)} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "beneficiaries",
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "beneficiaries",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "beneficiaries",
                                                '': getPath(2) !== "beneficiaries",
                                            })}>
                                                <HiUsers className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "beneficiaries",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "beneficiaries",
                                                })} />
                                            </div>
                                            <div>Beneficiaries</div>
                                        </div>
                                    </button>

                                    <button onClick={() => handleMoreNavigate(`/u/account`)} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && !getPath(3),
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "account",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && !getPath(3),
                                                '': getPath(2) !== "account",
                                            })}>
                                                <FaUserEdit className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && !getPath(3),
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account",
                                                })} />
                                            </div>
                                            <div>My Profile</div>
                                        </div>
                                    </button>

                                    <button onClick={() => handleMoreNavigate(`/u/account/kyc`)} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && getPath(3) === "kyc",
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "account" || getPath(3) !== "kyc",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && getPath(3) === "kyc",
                                                '': getPath(2) !== "account" || getPath(3) !== "kyc",
                                            })}>
                                                <AiTwotoneProfile className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && getPath(3) === "kyc",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account" || getPath(3) !== "kyc",
                                                })} />
                                            </div>
                                            <div>KYC</div>
                                        </div>
                                    </button>

                                    <div className='px-4 py-3'>
                                        <hr className='border-purple-300 border-2 border-opacity-30 dark:border-opacity-5' />
                                    </div>

                                    <button onClick={() => handleMoreNavigate(`/u/account/security`)} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && getPath(3) === "security",
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "account" || getPath(3) !== "security",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && getPath(3) === "security",
                                                '': getPath(2) !== "account" || getPath(3) !== "security",
                                            })}>
                                                <HiShieldCheck className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && getPath(3) === "security",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account" || getPath(3) !== "security",
                                                })} />
                                            </div>
                                            <div className=''>
                                                <div className='text-left'>Security</div>
                                                <div className='font-normal text-xs text-slate-500 text-opacity-80'>Protect your account</div>
                                            </div>
                                        </div>
                                    </button>

                                    <button onClick={() => handleMoreNavigate(`/u/support`)} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "support",
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "support",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "support",
                                                '': getPath(2) !== "support",
                                            })}>
                                                <FaQuestionCircle className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "support",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "support",
                                                })} />
                                            </div>
                                            <div className=''>
                                                <div className='text-left'>Support</div>
                                                <div className='font-normal text-xs text-slate-500 text-opacity-80'>Chat with support team</div>
                                            </div>
                                        </div>
                                    </button>

                                    <button onClick={() => logout()} className={classNames(' px-4 py-3 text-[15px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "logout",
                                        'text-neutral-900 dark:text-neutral-200 text': getPath(2) !== "logout",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 text-[18px] rounded-full flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "logout",
                                                '': getPath(2) !== "logout",
                                            })}>
                                                <FaDoorOpen className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "logout",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "logout",
                                                })} />
                                            </div>
                                            <div>Logout</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Show>
        </>
    )
}

export default MobileFooterTab