import React, { useState } from 'react'
import http from '@/lib/http';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Hide,
    Show,
    useToast,
} from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { handleResponseError } from '@/utils/errors';
import { walletConfig, walletList } from '@/utils/constants';
import { FaSpinner } from 'react-icons/fa6';
// import { useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import { IWallet } from '@/types/user.types';

function CreateWallet({ children, activeWallet }: { children: React.ReactNode, activeWallet: number }) {

    //   const navigate = useNavigate();
    const { user, wallets, setWallets } = useApp();

    const { isOpen: isCreateWalletModalOpen, onOpen: onCreateWalletModalOpen, onClose: onCreateWalletModalClose } = useDisclosure()
    const [isCreatingWallet, setIsCreatingWallet] = useState(false)
    const [walletPopupTab, setWalletPopupTab] = useState<"confirm" | "success">("confirm")
    const toast = useToast();

    function handleOnCreateWalletModalOpen() {
        const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
        if (getBvnKycInfo.status === "Pending") {
            toast({
                title: 'Error',
                description: 'Complete kyc to continue',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        } else {
            setWalletPopupTab("confirm")
            setIsCreatingWallet(false)
            onCreateWalletModalOpen();
        }
    }

    function handleOnCreateWalletModalClose() {
        onCreateWalletModalClose();
        setIsCreatingWallet(false);
        setWalletPopupTab("confirm");
    }

    async function handleCreateWallet() {
        setIsCreatingWallet(true);
        console.log("Wallets from state:", wallets)
        try {
            await http("wallet/create", {currency: walletList[activeWallet]});
            const resp = await http<IWallet[]>("wallet/details");
            console.log("Wallets:", resp.data);
            resp.data && setWallets(resp.data);
            setIsCreatingWallet(false);
            setWalletPopupTab("success");
        } catch (error) {
            console.log(error);
            setIsCreatingWallet(false);
            handleResponseError(error, toast);

        }
    }

    return (
        <>
            <div onClick={handleOnCreateWalletModalOpen} className='inline-block cursor-pointer'>{children}</div>
            <Hide below='md'>
                <Modal isOpen={isCreateWalletModalOpen} onClose={handleOnCreateWalletModalClose} closeOnOverlayClick={false}>
                    <ModalOverlay />
                    <ModalContent>
                        {/* <ModalHeader>Title</ModalHeader> */}
                        <ModalCloseButton className='text-neutral-800 dark:text-white' />
                        <ModalBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
                            {walletPopupTab === "success" ?

                                <div className='py-8'>
                                    <div className='flex mb-2 items-center gap-3'>
                                        <div className='font-extrabold text-2xl'>Wallet created</div>
                                        <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                                    </div>
                                    <div className='mb-10'>{walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet created successfully. Now you can add funds for keeps</div>
                                    <div className='w-full flex justify-center mb-10'>
                                        <img src="/assets/shield-check.png" className='w-[110px]' alt="" />
                                    </div>
                                </div> :
                                <div className='py-8'>
                                    <div className='flex mb-2 items-center gap-3'>
                                        <div className='font-extrabold text-2xl'>Create wallet</div>
                                        <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                                    </div>
                                    <div className='mb-5'>You are about to create {walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet</div>
                                    <div className='w-full flex justify-center mb-10'>
                                        <img src="/assets/wallet-img2.svg" className='w-[300px]' alt="" />
                                    </div>
                                    <center>
                                        <button className='py-2 px-5 flex gap-3 items-center bg-purple-800 rounded-xl text-neutral-200 font-bold disabled:bg-opacity-50' onClick={() => handleCreateWallet()} disabled={isCreatingWallet}>
                                            {!isCreatingWallet ? <div>Create wallet</div> : <div>Creating</div>}
                                            {isCreatingWallet && <div className='animate-spin'><FaSpinner /></div>}
                                        </button>
                                    </center>
                                </div>}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Hide>

            <Show below='md'>
                <Drawer placement={"bottom"} isOpen={isCreateWalletModalOpen} onClose={handleOnCreateWalletModalClose} closeOnOverlayClick={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton className='text-neutral-800 dark:text-white' />
                        <DrawerBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
                            {walletPopupTab === "success" ?

                                <div className='py-8'>
                                    <div className='flex mb-2 items-center gap-3'>
                                        <div className='font-extrabold text-2xl'>Wallet created</div>
                                        <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                                    </div>
                                    <div className='mb-10'>{walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet created successfully. Now you can add funds for keeps</div>
                                    <div className='w-full flex justify-center mb-10'>
                                        <img src="/assets/shield-check.png" className='w-[110px]' alt="" />
                                    </div>
                                </div> :
                                <div className='py-8'>
                                    <div className='flex mb-2 items-center gap-3'>
                                        <div className='font-extrabold text-2xl'>Create wallet</div>
                                        <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                                    </div>
                                    <div className='mb-5'>You are about to create {walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet</div>
                                    <div className='w-full flex justify-center mb-10'>
                                        <img src="/assets/wallet-img2.svg" className='w-[300px]' alt="" />
                                    </div>
                                    <center>
                                        <button className='py-2 px-5 flex gap-3 items-center bg-purple-800 rounded-xl text-neutral-200 font-bold disabled:bg-opacity-50' onClick={() => handleCreateWallet()} disabled={isCreatingWallet}>
                                            {!isCreatingWallet ? <div>Create wallet</div> : <div>Creating</div>}
                                            {isCreatingWallet && <div className='animate-spin'><FaSpinner /></div>}
                                        </button>
                                    </center>
                                    <div className='py-[22px]'></div>
                                </div>}
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Show>
        </>
    )
}

export default CreateWallet