import { useTheme } from '@/contexts/themeProvider';
import http from '@/lib/http';
import { ITransaction } from '@/types/global.types';
import { walletConfig, walletList } from '@/utils/constants';
// import { handleResponseError } from '@/utils/errors';
import { currencyFormat, formatDate, formatDateCustom } from '@/utils/helpers';
import { Skeleton } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react'
import { BsViewList } from 'react-icons/bs';
// import { FaAngleRight, FaUser } from 'react-icons/fa6';
import { HiMiniWallet } from 'react-icons/hi2'
import { RiSendPlaneFill } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom';

function Transactions() {

  // const toast = useToast();
  const { resolvedTheme } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [isFetching, setIsFetching] = useState(false);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const [filterInput, setFilterInput] = useState({
    date_filter_from: "",
    date_filter_to: ""
  });

  const urlWallet = useMemo(() => {
    const t = searchParams.get("wallet");
    if (!t) return "ngn";
    if (walletList.includes(t)) {
      return t;
    } else {
      return "ngn"
    }
  }, [searchParams])

  const [walletsTab, setWalletsTab] = useState<"ngn" | "usd" | "eur" | "gbp">(urlWallet as any);

  async function getTransactions(filter: object={}) {
    setIsFetching(true)
    try {
      const resp = await http(`wallet/transactions`, { currency: walletsTab, ...filter });
      resp.data ? setTransactions(resp.data as any) : setTransactions([]);
      console.log("FIlter:", { currency: walletsTab, ...filter })
      // resp && setTransactions([
      //   {
      //     "total_amount": "50000",
      //     "timestamp": "2023-11-28 17:22:14",
      //     "status": "Successful",
      //     "reference_code": "fc102aa6f3c4a17288e7682b9c2df7c8",
      //     "balance_before": "9950000",
      //     "balance_after": "9900000",
      //     "type": "Debit",
      //     "remark": "",
      //     "attach": {
      //       "number": "0126069407",
      //       "name": "Bakare Abdulquadry Olajide",
      //       "bank": "Gtbank Plc",
      //       "code": "000013",
      //       "reference": "3060"
      //     }
      //   }
      // ])
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      // handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getTransactions(filterInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsTab, filterInput]);
  

  const getCurrencyIcon = useMemo(() => {
    return walletConfig.find((e) => e.currency === walletsTab)?.icon;
  }, [walletsTab])

  function changeTab(tab: any) {
    setWalletsTab(tab);
    setSearchParams({ wallet: tab });
  }

  function shortenName(name: string) {
    if (name.length === 0) return name;
    const s = name.split(" ");
    if (s.length < 1) return name;
    return s[0];
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>

        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Transactions</div>
        </div>

        <div className='mb-10 px-6'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              <button onClick={() => changeTab("ngn")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "ngn",
                '': walletsTab !== "ngn"
              })}> <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>NGN</div></button>
              <button onClick={() => changeTab("usd")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "usd",
                '': walletsTab !== "usd"
              })}> <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>USD</div></button>
              <button onClick={() => changeTab("eur")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "eur",
                '': walletsTab !== "eur"
              })}> <img src="/assets/flags/eur.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>EUR</div></button>
              <button onClick={() => changeTab("gbp")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "gbp",
                '': walletsTab !== "gbp"
              })}> <img src="/assets/flags/gbp.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>GBP</div></button>
            </div>
          </div>
        </div>

        <div className='px-7 pb-6'>
          {/* <div className='text-xs mb-3'>Filter</div> */}
          <div className='grid grid-cols-2 gap-3 w-full md:w-[400px]'>
            <div>
              <div className='text-sm'>From date</div>
              <div><input onChange={(e) => setFilterInput(i => ({...i, date_filter_from: formatDate(e.target.value)}))} type="datetime-local" className='px-4 py-2 w-full bg-slate-300 bg-opacity-45 dark:bg-neutral-700 dark:bg-opacity-75' /></div>
            </div>

            <div>
              <div className='text-sm'>To date</div>
              <div><input onChange={(e) => setFilterInput(i => ({...i, date_filter_to: formatDate(e.target.value)}))} type="datetime-local" className='px-4 py-2 w-full bg-slate-300 bg-opacity-45 dark:bg-neutral-700 dark:bg-opacity-75' /></div>
            </div>
          </div>
        </div>

        <div className='px-7'>
          <div className='px-0'>

            {!isFetching ?
              transactions.length > 0 ?
                transactions.map((t, i) => (
                  <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                    <div className='flex gap-3 items-center'>
                      <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                        {t.type === "Debit" ? <RiSendPlaneFill className="w-6 h-6" /> : <HiMiniWallet className="w-6 h-6" />}
                      </div>
                      <div>
                        <div className='font-bold text-sm'>{t.type === "Debit" ? `Transfer to ${shortenName(t.attach.name)}` : `Wallet Funding`}</div>
                        <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>{formatDateCustom(t.timestamp)}</div>
                      </div>
                    </div>
                    <div>
                      <div className={classNames('text-right font-bold text-sm', {
                        'text-green-600': t.type === "Credit",
                        'text-red-600': t.type === "Debit"
                      })}>{t.type === 'Debit' ? '-' : '+'} {currencyFormat(t.total_amount, getCurrencyIcon)}</div>
                      <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>
                        {
                          t.type === "Debit" ? "Wallet payment" : "Funding"
                        }
                      </div>
                    </div>
                  </div>
                )) :
                <div className='w-full h-[250px] flex justify-center px-5 items-center'>
                  <div className='text-slate-900 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70 font-light'>
                    <center>
                      <div className='mb-5 text-lg'>No record</div>
                      <BsViewList className='text-[30px]' />
                    </center>
                  </div>
                </div>
              :
              Array.from(Array(5)).map((_, i) => (
                <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                  <div className='flex gap-3 items-center'>
                    <Skeleton
                      startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                      endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                      w={'50px'} h={'50px'} borderRadius={'10%'} />
                    <div>
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'150px'} h={'14px'} mb={1} borderRadius={'10%'} />
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'60px'} h={'10px'} mb={1} borderRadius={'10%'} />
                      <Skeleton
                        startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                        endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                        w={'80px'} h={'10px'} mb={1} borderRadius={'10%'} />
                    </div>
                  </div>
                  <div>
                    <Skeleton
                      startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                      endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                      w={'40px'} h={'10px'} mb={1} borderRadius={'10%'} />
                  </div>
                </div>
              ))
            }

          </div>
        </div>

        {/* <div className='px-7'>
          <div className='px-0'>
            <div className='flex justify-between gap-3 items-center mb-3'>
              <div className='flex gap-3 items-center'>
                <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                  <RiSendPlaneFill className="w-6 h-6" />
                </div>
                <div>
                  <div className='font-bold text-sm'>Transfer to zang</div>
                  <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>25 januery 2024</div>
                </div>
              </div>
              <div>
                <div className='text-right font-bold text-sm text-red-600'>- $ 60,000</div>
                <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>Wallet payment</div>
              </div>
            </div>

            <div className='flex justify-between gap-3 items-center mb-3'>
              <div className='flex gap-3 items-center'>
                <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                  <HiMiniWallet className="w-6 h-6" />
                </div>
                <div>
                  <div className='font-bold text-sm'>Wallet Funding</div>
                  <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>25 januery 2024</div>
                </div>
              </div>
              <div>
                <div className='text-right font-bold text-sm text-green-600'>+ ₦ 1,210,000</div>
                <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>Bank transfer</div>
              </div>
            </div>

            <div className='flex justify-between gap-3 items-center mb-3'>
              <div className='flex gap-3 items-center'>
                <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                  <RiSendPlaneFill className="w-6 h-6" />
                </div>
                <div>
                  <div className='font-bold text-sm'>Transfer to peter</div>
                  <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>25 januery 2024</div>
                </div>
              </div>
              <div>
                <div className='text-right font-bold text-sm text-red-600'>- $ 5,000</div>
                <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>Card payment</div>
              </div>
            </div>


            <div className='flex justify-between gap-3 items-center mb-3'>
              <div className='flex gap-3 items-center'>
                <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                  <HiMiniWallet className="w-6 h-6" />
                </div>
                <div>
                  <div className='font-bold text-sm'>Wallet Funding</div>
                  <div className='text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>25 januery 2024</div>
                </div>
              </div>
              <div>
                <div className='text-right font-bold text-sm text-green-600'>+ ₦ 143,000</div>
                <div className='text-right text-xs text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>Bank transfer</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Transactions