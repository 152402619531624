
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import CustomInput from '@/components/CustomInput';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import { CgSpinner } from "react-icons/cg";
import CustomFileInput from '@/components/CustomFileInput';
import { FaCheckCircle } from 'react-icons/fa';

function OnboardVerifyAddress() {

    const toast = useToast();
    const navigate = useNavigate();
    const { user, setUser } = useApp();

    const [verifyAddressInput, setVerifyAddressInput] = useState({
        state: "",
        city: "",
        photo_url: "",
        street: "",
        postal_code: "",
    });
    const [isFetching, setIsFetching] = useState(false);
    const [selfieImage, setSelfieImage] = useState<File | null>(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    async function verifyAddressKyc() {
        setIsFetching(true);
        try {
            const resp = await http("user/verification/process/residential-address", { ...verifyAddressInput });
            const newKycDetails = [...user!.kyc.details];
            const index = newKycDetails.findIndex((k) => k.parameter === "residential-address");
            if (index !== -1) {
                newKycDetails[index].status = "Successful"
                setUser((u) => ({ ...u!, kyc: { ...u!.kyc, details: newKycDetails } }));
            }
            toast({
                title: 'Success',
                description: resp.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            navigate("/u/account/kyc?tier=3");
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function handleOnImageSelected(file: File | null) {
        setSelfieImage(file);
        if (!file) return;
        setVerifyAddressInput((v) => ({ ...v, photo_url: "" }));
        setIsUploadingFile(true);
        try {
            const formData = new FormData();
            formData.append('file', file);
            const uploadResp = await http<string>("misc/file-upload", formData, process.env.REACT_APP_PUBLIC_KEY);
            console.log("upload response", uploadResp.data);
            setVerifyAddressInput((v) => ({ ...v, photo_url: uploadResp.data! }));
            setIsUploadingFile(false);
        } catch (error) {
            console.log(error);
            setIsUploadingFile(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div>
                <div className='overflow-auto top-0 left-0 pt-[100px] flex justify-center items-center w-full z-50 bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100'>
                    <div className='w-full md:w-[500px]'>
                        <div>
                            <div className='text-right px-8 mb-2'>
                                <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={() => navigate(-1)}>Skip</button>
                            </div>
                            <div className='mb-8 pt-[30px] px-6'>

                                <div className='mb-8'>
                                    <div className='mb-1 font-extrabold text-xl'>Submit Proof of residence</div>
                                    <div>Kindly enter proof of residential address</div>
                                </div>

                                <div className='mb-6'>
                                    <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> State</div>
                                    <CustomInput
                                        type='text'
                                        name='state'
                                        value={verifyAddressInput.state}
                                        onChange={(e) => setVerifyAddressInput((i) => ({ ...i, state: e }))}
                                        placeholder='State'
                                        disabled={isFetching}
                                    />
                                </div>

                                <div className='mb-6'>
                                    <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> City</div>
                                    <CustomInput
                                        type='text'
                                        name='city'
                                        value={verifyAddressInput.city}
                                        onChange={(e) => setVerifyAddressInput((i) => ({ ...i, city: e }))}
                                        placeholder='City'
                                        disabled={isFetching}
                                    />
                                </div>

                                <div className='mb-6'>
                                    <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Street</div>
                                    <CustomInput
                                        type='text'
                                        name='street'
                                        value={verifyAddressInput.street}
                                        onChange={(e) => setVerifyAddressInput((i) => ({ ...i, street: e }))}
                                        placeholder='Street'
                                        disabled={isFetching}
                                    />
                                </div>

                                <div className='mb-6'>
                                    <div className='text-sm font-normal mb-1'>Postal code</div>
                                    <CustomInput
                                        type='number'
                                        name='postal_code'
                                        value={verifyAddressInput.postal_code}
                                        onChange={(e) => setVerifyAddressInput((i) => ({ ...i, postal_code: e }))}
                                        placeholder=''
                                        disabled={isFetching}
                                    />
                                </div>

                                <div className='mb-10'>
                                    <div className='text-sm font-normal mb-1 flex gap-1 items-center'>
                                        <span className='text-red-600'>*</span>
                                        <div className='flex items-center gap-2'>
                                            <span>Upload utilty bill or <br /> statement of account to proof address</span>
                                            {isUploadingFile && <CgSpinner className='animate-spin' />}
                                            {selfieImage !== null && verifyAddressInput.photo_url && <FaCheckCircle className='text-green-700 dark:text-green-600' />}
                                        </div>
                                    </div>
                                    <CustomFileInput
                                        onChange={(f) => handleOnImageSelected(f)}
                                        disabled={isUploadingFile || isFetching}
                                    />
                                </div>

                                <div className='mb-5'>
                                    <button onClick={() => verifyAddressKyc()} disabled={isFetching} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                        {
                                            isFetching ?
                                                <CgSpinner className='animate-spin' /> :
                                                <span>Submit</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='py-10'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardVerifyAddress