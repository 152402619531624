
import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import SplashLoader from '../SplashLoader';
import { BsExclamationCircle } from 'react-icons/bs';

function OnboardLayout() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isAuthenticating, isAuthenticated, showOnboard, onlineStatus } = useApp();

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    useEffect(() => {
        if (!isAuthenticating && !isAuthenticated) {
            localStorage.removeItem('op-app-tkn');
            navigate('/auth/login', { replace: true });
        };
        if (showOnboard) {
            if (showOnboard === "phone" && getPath(1) !== "onboarding" && getPath(2) !== "verify-phone") {
                navigate('/onboarding/verify-phone', { replace: true });
            }
            if (showOnboard === "email" && getPath(1) !== "onboarding" && getPath(2) !== "verify-email") {
                navigate('/onboarding/verify-email', { replace: true });
            }
            if (showOnboard === "bvn" && getPath(1) !== "onboarding" && getPath(2) !== "verify-bvn") {
                navigate('/onboarding/verify-bvn', { replace: true });
            }
        }
        console.log("showOnboard", showOnboard)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticating, isAuthenticated, showOnboard]);

    return (
        <>
            {
                onlineStatus === "offline" &&
                <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-red-900 relative z-[9999] text-neutral-100'>
                    <div className='flex items-center gap-2'>
                        <BsExclamationCircle />
                        <div className='text-xs'>You are offline. kindly check internet connection</div>
                    </div>
                    {/* <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link> */}
                </div>
            }
            {(!isAuthenticating && isAuthenticated)
                &&
                ((showOnboard === "phone" && getPath(1) === "onboarding" && getPath(2) === "verify-phone")
                || (showOnboard === "email" && getPath(1) === "onboarding" && getPath(2) === "verify-email")
                    || (showOnboard === "bvn" && getPath(1) === "onboarding" && getPath(2) === "verify-bvn") || showOnboard === null) ?
                <>
                    <div className='bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100 font-container'>
                        <Outlet />
                    </div>
                </>
                :
                <SplashLoader />
            }
        </>
    )
}

export default OnboardLayout