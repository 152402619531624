import { useApp } from "@/contexts/appContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsExclamationCircle } from "react-icons/bs";

function Loader() {

    const { onlineStatus } = useApp();

    return (
        <>
            {
                onlineStatus === "offline" &&
                <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-red-900 relative z-[9999] text-neutral-100'>
                    <div className='flex items-center gap-2'>
                        <BsExclamationCircle />
                        <div className='text-xs'>You are offline. kindly check internet connection</div>
                    </div>
                    {/* <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link> */}
                </div>
            }
            <div className='w-full flex h-screen justify-center bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-100'>
                <div className='mt-[300px] lg:mt-[350px]'>
                    <center className=''>
                        <AiOutlineLoading3Quarters className='w-8 h-8 text-purple-700 animate-spin mb-10' />
                        <div className='animate-ping'>
                            ORION PAY
                        </div>
                    </center>
                </div>
            </div>
        </>
    )
}

export default Loader