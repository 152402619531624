import { useApp } from '@/contexts/appContext';
import classNames from 'classnames';
import React, { useState } from 'react'
import { BiCopy } from 'react-icons/bi';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function PayReceive() {

  const navigate = useNavigate();
  const {wallets} = useApp();
  const [walletsTab, setWalletsTab] = useState<"ngn" | "usd" | "eur" | "gbp">("ngn");

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='px-6'>
          <div className='block md:flex w-full justify-center mt-0 md:mt-[100px]'>
            <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-2 py-2 md:px-[60px] md:py-[50px] w-full lg:w-[70%] rounded-xl'>

              <div className='text-black dark:text-neutral-100 '>

                <div className='mb-10'>
                  <div className='flex items-center gap-5'>
                    <button className='' onClick={() => navigate(-1)}>
                      <IoArrowBackCircleOutline className="w-8 h-8" />
                    </button>
                    <div className='font-extrabold text-xl'>Receive</div>
                  </div>
                </div>

                <div className='mb-10 pb-5 px-0 md:px-6 border-b-2'>
                  <div className='flex justify-between'>
                    <div className='flex items-center gap-2'>
                      {wallets.map((e) => e.currency.toLowerCase()).includes("ngn") && <button onClick={() => setWalletsTab("ngn")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "ngn",
                        '': walletsTab !== "ngn"
                      })}> <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>NGN</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("usd") && <button onClick={() => setWalletsTab("usd")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "usd",
                        '': walletsTab !== "usd"
                      })}> <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>USD</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("eur") && <button onClick={() => setWalletsTab("eur")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "eur",
                        '': walletsTab !== "eur"
                      })}> <img src="/assets/flags/eur.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>EUR</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("gbp") && <button onClick={() => setWalletsTab("gbp")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "gbp",
                        '': walletsTab !== "gbp"
                      })}> <img src="/assets/flags/gbp.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>GBP</div></button>}
                    </div>
                  </div>
                </div>

                <div className='px-5 md:px-10'>
                  <div className='text-xs mb-1'>WALLET ID</div>
                  <div className=' flex items-center gap-3 mb-1'>
                    <div className='font-extrabold text-xl'>{wallets.find((e) => e.currency.toLowerCase() === walletsTab.toLowerCase())?.wallet_id}</div>
                    <div><BiCopy /></div>
                  </div>
                  <div className='text-sm text-neutral-700 dark:text-neutral-500 md:w-[300px]'>Share your wallet ID and receive payments instantly from orion pay users.</div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>
    </>
  )
}

export default PayReceive